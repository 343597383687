<template>
  <div class="meeting-record wrapper page-wrap">
    <a-spin :spinning="loading">
      <div style="padding-bottom: 50px" v-if="meeting_book">
        <div class="flex flex-between meeting-box-main">
          <template v-for="(item, index) in meeting_book" :key="index">
            <div
              class="meeting-item flex flex-between"
              @click="goBookDetail(item)"
            >
              <div class="metting-info">
                <div class="flex">
                  <span class="font-18 font-bold">{{ item.meeting_name }}</span>
                  <span
                    :class="{
                      book_status: true,
                      'label-gray': item.book_status == -1,
                      'label-wait':item.book_status==0
                    }"
                    >{{ getMeetStatus(item.book_status) }}</span
                  >
                </div>
                <div class="mt-10 font-16">
                  <span class="text-black" style="margin-right: 10px">
                    {{ item.book_date }}</span
                  ><span
                    >{{ item.book_start_time }}-{{ item.book_end_time }}</span
                  >
                </div>
              </div>
              <right-outlined />
            </div>
          </template>
        </div>
      </div>
      <a-pagination
        v-model:current="page"
        v-model:pageSize="limit"
        :defaultPageSize="16"
        :hideOnSinglePage="true"
        show-size-changer
        :total="total"
        :pageSizeOptions="['16', '30', '60', '120']"
        @showSizeChange="onShowSizeChange"
      />
    </a-spin>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
} from "@vue/runtime-core";
import { getUserMeetingBookList } from "@/api/main.js";
import { message } from "ant-design-vue";
import url from "@/utils/url.js";
import { getUserId, getUserData } from "@/utils/storeData.js";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { encode, decode } from "js-base64";
import { RightOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "MeetingRecord",
  components: {
    RightOutlined,
  },
  setup() {
    const route = useRoute();
    const pageData = reactive({
      loading: true,
      userData: computed(() => getUserData()),
      user_id: computed(() => getUserId()),
      meeting_book: null,
      page: 1,
      limit: 16,
      total: 0,
    });
    const getUserMeetingBookListFun = () => {
      getUserMeetingBookList({
        page: pageData.page,
        limit: pageData.limit,
        park_id: pageData.userData?.user_park_id,
        user_id: pageData.user_id,
      })
        .then((res) => {
          if (res.code == 200) {
            pageData.meeting_book = res.data.meeting_book;
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const goBookDetail = (item) => {
      url.navigateTo("/meeting/applyDetail", {
        t: encode(`${item.book_id}`),
      });
    };
    const onShowSizeChange = (_page, _limit) => {
      pageData.page = _page;
      pageData.limit = _limit;
    };
    watch(
      [
        () => pageData.userData.user_park_id,
        () => pageData.page,
        () => pageData.limit,
      ],
      (val) => {
        getUserMeetingBookListFun();
      },
      {
        immediate: true,
        deep: true,
      }
    );
    const getMeetStatus = (val) => {
      if (val == 1) {
        return "预订成功";
      } else if (val == -1) {
        return "取消预定";
      } else {
        return "等待审核";
      }
    };
    return {
      ...toRefs(pageData),
      goBookDetail,
      onShowSizeChange,
      getMeetStatus,
    };
  },
});
</script>
<style lang="less" scoped>
.meeting-record {
  .meeting-box-main {
    flex-wrap: wrap;
    .meeting-item {
      width: 570px;
      padding: 30px 0;
      border-bottom: 2px solid #dfdfdf;
      cursor: pointer;

      .metting-info {
        .book_status {
          width: 81px;
          height: 23px;
          line-height: 23px;
          border-radius: 11px;
          border: solid 1px #1276cb;
          color: #1276cb;
          font-size: 14px;
          text-align: center;
          margin-left: 20px;
        }
        .label-wait {
          color: #55adff !important;
          border: solid 1px #55adff !important;
        }
      }
    }
  }
  .ant-pagination {
    text-align: center;
    margin-top: 30px;
  }
}
</style>
